import React from "react"
import { Layout, Title, Cartoon } from "../components/index"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import {Helmet} from 'react-helmet';
import Favicon from '../img/favicon3.png';


export const query = graphql`
  query {
    file(relativePath: { eq: "ANIMIRANI-FILM-konradova-prodavaonica-ptica-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({ data }) => (
      <div className="animirani-film">
          <Helmet>
                <title>Animirani filmovi - Umjetnička organizacija Kreativna Kuća</title>
                <meta name='title' content='Animirani filmovi - Umjetnička organizacija Kreativna Kuća' />
                <meta name='description' content='KREATIVNA KUĆA je umjetnička organizacija osnovana 2018. godine koja se bavi objavljivanjem i distribucijom kvalitetnih književnih djela, izradom autorskih animiranih filmova i organiziranjem i provedbom kreativnih radionica i manifestacija vezanih za književnost i animirani film. Naša je misija povezivanje zbilje i mašte u originalne umjetničke izričaje i širenje prostora stvaralačke slobode. Osnivači i glavni provoditelji programa Umjetničke organizacije KREATIVNA KUĆA su višestruko nagrađivana književnica i scenaristica Nena Lončar i likovni umjetnik Marijan Lončar.' />
                <link rel='shortcut icon' type='image/x-icon' href={Favicon} />
                <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
            </Helmet>
        <Layout>
          <Title title='Animirani film' subtitle='Animirani filmovi Kreativne Kuće'></Title>
          <div className="cartoon-wrapper">
            <Cartoon title='Konradova prodavaonica ptica'>
              <p>Konrad je dječak koji neobično voli ptice. Nema tih pernatih stvorenja koje on ne umije imenovati; od sasvim sićušnih koja stanu u kap vode do onih čiji  raspon krila seže do nekoliko metara. S lakoćom prepoznaje njihov pjev. Zadivljen je svime vezanim za te divne nebeske letače, osobito načinom na koji se kreću. Da može i sam bi  poletio s njima.</p>
              <p>Konrad sa svojom ostarjelom bakom živi daleko izvan grada i svakodnevno se vere po stablima. Ako ne bere jabuke ili kakvo drugo voće za najbolje kolače na svijetu koje peče njegova baka, nježno vraća ptiće u gnijezda iz kojih katkad  ispadnu. Zimi, kad selo zamete snijeg, brine se da imaju dovoljno hrane, Kaže, kad naraste bit će dadilja za ptice. Ljudi koji ga spaze negdje visoko u krošnji, zbog njegove ga narančaste kose  često zamjene za vjevericu. Onoga dana kada Konradova baka mora preseliti na nebo, Konrad  mora preseliti u grad k ujaku Arturu, daleko od svojih ptica.</p>
              <p>-film je u fazi predprodukcije</p>
            </Cartoon>
            <Img fluid={data.file.childImageSharp.fluid} className='cartoon-img' alt='animirani film konradova prodavaonica ptica' />
            </div>
        </Layout>
      </div>
)